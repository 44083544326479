import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Location, LocationContext, WindowLocation } from '@reach/router'
import GetNewsNotification from 'src/domain/usecases/GetNewsNotification'
import RemoveNewsNotification from 'src/domain/usecases/RemoveNewsNotification'
import LocalNotificationRepository from 'src/data/storage/localStorage/NotificationRepository'
import TypographyLink from 'src/components/Typography/Link'
import NavLink from 'src/components/NavLink'
import Wrapper from './style'

interface Props {
  isMenuOpen: boolean
  handleMenuToggle: any
  location: WindowLocation
}

const DesktopMenu: React.FC<Props> = props => {
  const { isMenuOpen, handleMenuToggle, location } = props
  const { pathname } = location

  const [hasNotification, setHasNotification] = useState(false)

  const newsQueryData = useStaticQuery(graphql`
    query {
      allWordpressWpNews(limit: 1, sort: {fields: id, order: ASC}) {
        edges {
          node {
            wordpress_id
          }
        }
      }
    }
  `)

  const latestNewsId = newsQueryData.allWordpressWpNews.edges[0].node.wordpress_id

  useEffect(() => {
    const getNewsNotification = async () => {
      const repository = new LocalNotificationRepository()
      const useCase = new GetNewsNotification(repository)
      const hasNotification = await useCase.execute(latestNewsId)
      setHasNotification(hasNotification)
    }
    getNewsNotification()
  }, [latestNewsId])

  useEffect(() => {
    const isNewsPath = pathname.startsWith('/news')
    if (isNewsPath && hasNotification) {
      const removeNewsNotification = async () => {
        const repository = new LocalNotificationRepository()
        const useCase = new RemoveNewsNotification(repository)
        await useCase.execute(latestNewsId)
        setHasNotification(false)
      }
      removeNewsNotification()
    }
  }, [pathname, hasNotification, latestNewsId])

  return (
    <Wrapper className={isMenuOpen ? 'desktop open' : 'desktop'}>
      <div className="desktop list-menu">
        <label
          className="close-icon interactive"
          onClick={(event) => handleMenuToggle(event, 'desktop')}
        />

        <ul className="menu-nav">
          <li>
            <TypographyLink simple>
              <NavLink to="/expositions">ВЫСТАВКИ</NavLink>
            </TypographyLink>
          </li>
          <li>
            <TypographyLink simple badge={hasNotification}>
              <NavLink to="/news">НОВОСТИ</NavLink>
            </TypographyLink>
          </li>
          <li>
            <TypographyLink simple>
              <NavLink to="/about">О ГАЛЕРЕЕ</NavLink>
            </TypographyLink>
          </li>
        </ul>

        <ul className="menu-socials">
          <li>
            <TypographyLink simple>
              <a
                //eslint-disable-next-line
                target="_blank"
                rel="nofollow noopener"
                href="https://vk.com/psgallery"
              >VKONTAKTE</a>
            </TypographyLink>
          </li>
          <li>
            <TypographyLink simple>
              <a
                //eslint-disable-next-line
                target="_blank"
                rel="nofollow noopener"
                href="https://www.facebook.com/psgalleryru/"
                >FACEBOOK</a>
            </TypographyLink>
          </li>
          <li>
            <TypographyLink simple>
              <a
                //eslint-disable-next-line
                target="_blank"
                rel="nofollow noopener"
                href="https://www.instagram.com/psgallery.ru/"
              >INSTAGRAM</a>
            </TypographyLink>
          </li>
        </ul>

        <TypographyLink simple>
          <a href="mailto:psgallery@yandex.ru" className="mailto">psgallery@yandex.ru</a>
        </TypographyLink>

      </div>

    </Wrapper>
  )
}

interface NewProps {
  isMenuOpen: boolean
  handleMenuToggle: any
}

const DesktopMenuWithLocation: React.FC<NewProps> = props => {
 const NavProps = props

  return (
    <Location>
      {(props: LocationContext) => {
        return <DesktopMenu location={props.location} {...NavProps} />
      }}
    </Location>
  )
}

export default DesktopMenuWithLocation
