import NotificationsRepository from '../repositories/NotificationsRepository'

class GetNewsNotification {
  repository: NotificationsRepository

  constructor(repository: NotificationsRepository) {
    this.repository = repository
  }

  execute(id: string): Promise<boolean> {
    return this.repository.getNewsNotification(id)
  }
}

export default GetNewsNotification
