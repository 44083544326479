import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  display: block;
  float: right;
  z-index: 1500;
  height: 100vh;
  position: fixed;
  width: 40rem!important;
  margin: 0 0 0 auto!important;
  transform: translateX(100%);
  right: 0;
  transition: transform .3s ease-out .3s;

  label.hamburger-icon {
    display: flex;
    width: 2.6rem;
    height: 1.6rem;
    background-color: ${props => props.theme.colors.backgroundLight};
    background-image: url(${require('src/static/icons/BurgerMenu.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1)!important;
    transition: transform .3s ease-out;
    cursor: pointer;
  }

  .list-menu {
    display: flex!important;
    flex-flow: column;
    background-color: ${props => props.theme.colors.backgroundDark};
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    padding: 4.5rem 7rem;
    text-align: center;
    transition: transform 0s linear 1s,
                opacity .3s ease .3s;

    ${up('tablet')} {
      height: 100vh;
    }

    ${up('desktop')} {
      padding: 7rem;
    }

    label {
      cursor: pointer;
    }

    label.close-icon {
      opacity: 0;
      display: block;
      width: 3rem;
      height: 3rem;
      background-image: url(${require('src/static/icons/CloseMenu.svg')});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 0 1rem auto;
      position: relative;
      transition: opacity .3s ease;

      ${up('desktop')} {
        width: 3.5rem;
        height: 3.5rem;
        margin-bottom: 2.5rem;
      }
    }

    ul {
      display: flex;
      flex-flow: column;
      flex: 1 0 auto;
      list-style: none;
      text-align: center;
      justify-content: center;
      padding: 1.6rem 0;
      position: relative;
      opacity: 0;
      transition: opacity .3s ease;

      & > li {
        display: block;
        padding: 1.6rem 0;
        text-align: center;
        opacity: 0;
        transition: opacity .3s ease;

        & > span, & > span > a {
          font-size: 1.2rem;
          color: ${props => props.theme.colors.bodyTextLight};

          &:hover, &:active, &.active {
            color: ${props => props.theme.colors.separator[300]};
          }
        }

        ${up('desktop')} {
          padding: 2rem 0;
        }

        ${up('largeDesktop')} {
          padding: 4rem 0;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 0;
        height: 1px;
        background-color: ${props => props.theme.colors.separator[700]};
        transition: width .3s ease;
      }
    }

    & > span {
      display: flex;
      flex-flow: column;
      flex: 1 0 auto;
      justify-content: center;
    }

    a.mailto {
      font-size: 1.2rem;
      margin-top: 3.5rem;
      color: ${props => props.theme.colors.subtitle};
      opacity: 0;
      transition: opacity .3s ease;

      ${up('desktop')} {
        margin: auto;
      }
    }
  }


  &.open {
    transform: translateX(0%);
    transition: transform .5s ease-out;

    div.list-menu {
      width: 100%;
      height: 100%;
      max-width: 100%;
      min-height: 100%;
      z-index: 2;
      opacity: 1;
      transform: scale(1);
      transition: transform 0s linear,
                  opacity .5s ease;

      ${up('tablet')} {
        height: 100vh;
      }

      & label.close-icon {
        opacity: 1;
        transition: opacity .3s ease .5s;
      }

      & ul.menu-nav {
        opacity: 1;
        transition: opacity .3s ease .3s;

        &::after {
          width: 22rem;
          transition: width .3s ease 1.2s;
        }

        & > li {
          opacity: 1;

          &:nth-child(1) {
            transition: opacity .3s ease .3s;
          }
          &:nth-child(2) {
            transition: opacity .3s ease .6s;
          }
          &:nth-child(3) {
            transition: opacity .3s ease .9s;
          }
          &:nth-child(4) {
            transition: opacity .5s ease 1.2s;
          }
        }

      }

      & ul.menu-socials {
        opacity: 1;
        transition: opacity .3s ease 1.5s;

        &::after {
          width: 22rem;
          transition: width .3s ease 2.1s;
        }

        & > li {
          opacity: 1;

          &:nth-child(1) {
            transition: opacity .3s ease 1.6s;
          }
          &:nth-child(2) {
            transition: opacity .3s ease 1.8s;
          }
          &:nth-child(3) {
            transition: opacity .3s ease 2.1s;
          }
        }
      }

      a.mailto {
        opacity: 1;
        transition: opacity .3s ease 2.4s;
      }
    }
  }
`

export default  Wrapper
