import NotificationsRepository from '../repositories/NotificationsRepository'

class RemoveNewsNotification {
  repository: NotificationsRepository

  constructor(repository: NotificationsRepository) {
    this.repository = repository
  }

  execute(id: string): Promise<void> {
    return this.repository.removeNewsNotification(id)
  }
}

export default RemoveNewsNotification
