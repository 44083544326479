import styled from 'styled-components'
import { up, down } from 'src/utils/media'

const StyledHeader = styled.header`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  transition: all .7s ease-out;
  /* background-color: ${props => props.theme.colors.backgroundLight}; */
  justify-content: center;
  z-index: 1;

  ${down('desktop')} {
    height: 8.3rem;
    border-bottom: 1px solid ${props => props.theme.colors.separator[300]};
    background-color: ${props => props.theme.colors.backgroundLight};
    padding: 2.8rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .grid-column {
    padding-left: 0;
    padding-right: 0;
  }

  & > div {
    width: ${props => props.theme.containerWidth.sm};
    margin-left: ${props => -props.theme.columnGutter.sm/2}px;
    margin-right: ${props => -props.theme.columnGutter.sm/2}px;

    ${up('tablet')} {
      width: ${props => props.theme.containerWidth.lg};
      margin-left: ${props => -props.theme.columnGutter.lg/2}px;
      margin-right: ${props => -props.theme.columnGutter.lg/2}px;
    }

    ${up('desktop')} {
      width: ${props => props.theme.containerWidth.xl};
      margin-left: ${props => -props.theme.columnGutter.xl/2}px;
      margin-right: ${props => -props.theme.columnGutter.xl/2}px;
    }

    .grid-row {
      padding: 0;

      ${up('tablet')} {
       margin-top: 4.5rem;
      }

      ${up('desktop')} {
       margin-top: 7rem;
      }

      & > div:nth-child(1) {
        align-items: flex-start;

        & > span {
          float: left;
        }
      }

      & > div:nth-child(2) {
        align-items: flex-end;

        & > span {
          float: right;
        }
      }

      & > div:nth-child(3) {
        align-items: flex-end;
        align-self: center;

        & > img {
          float: right;
          vertical-align: middle;
        }
      }

    }
  }

  & .logo {
    height: 2.6rem;
    width: auto;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    transition: all .6s ease-out;

    ${up('tablet')} {
      height: 2.7rem;
    }

    ${up('desktop')} {
      height: 3.5rem;
    }

    ${up('largeDesktop')} {
      height: 5.6rem;
    }

    & > svg {
      height: inherit;
      width: auto;
      object-fit: cover;

      & > * {
        opacity: 1;
        transition: all .3s ease-out .3s;
      }
    }
  }
  
  & .about {
    /* max-width: 50%;
    transition: all .3s ease-out; */
    max-width: unset;
    display: flex;
    margin-left: auto;
    width: auto;
    flex: 0 1 auto;

    & > span {      
      opacity: 1;
      transform: translateX(0);
      transition: opacity .3s ease-out .3s,
                  transform .3s ease-out .1s;
    }
  }

  & .menu {
    ${up('tablet')} {
      overflow: hidden;
      transform: translateX(-100%);
      height: 100%;
      max-width: 0;
      max-height: 0;
      opacity: 0;
      transition: max-height 0s ease-out,
                  opacity .3s ease-out,
                  transform .3s ease-out .3s,
                  max-width .3s ease-out;
    }
  }

  &.fixed {

    ${up('tablet')} {
      & > div {
        height: 0;
      }

      & .grid-row {
        height: 0;

        .grid-column:nth-child(1) {
          max-width: unset;
          display: flex;
          margin-right: auto;
          width: auto;
          flex: 0 1 auto;
        }
        .grid-column:nth-child(2) {
          max-width: unset;
          display: none;
          visibility: hidden;
          flex: 0 1 auto;
          transition: none!important;
        }
        .grid-column:nth-last-child(1) {
          max-width: unset;
          display: flex;
          margin-left: auto;
          width: auto;
          flex: 0 1 auto;

          ${up('tablet')} {
            width: 2.7rem;
          }

          ${up('desktop')} {
            width: 3.5rem;
          }

          ${up('largeDesktop')} {
            width: 5.6rem;
          } 
        }
      }

      .about {
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        visibility: hidden!important;
        transition: none!important;
      }

      .menu {
        justify-content: center;
      }
    }



    & .logo {
      transition: all .6s ease-out;
        
      ${up('tablet')} {
        max-width: 2.8rem;

        & > svg {
          & > *:not(.square) {
            opacity: 0;
            transition: opacity .3s ease-out,
                        transform .3s ease-out .3s;
          }
        }
      }

      ${up('desktop')} {
        max-width: 3.6rem;
      }

      ${up('largeDesktop')} {
        max-width: 5.7rem;
      }
    }

    & .about {
      max-width: 0;
      max-height: 0;
      transition: all .3s ease-out .3s;
      
      & > span {      
        opacity: 0;
        transform: translateX(100%);
        transition: all .3s ease-out;
      }
    }

    & .menu {
      ${up('tablet')} {
        overflow: visible;
        max-width: 50%;
        transform: translateX(0);
        opacity: 1;
        transition: max-height 0s ease-out,
                    opacity .3s ease-out .3s,
                    transform .3s ease-out .1s,
                    max-width .3s ease-out .3s;
      }
      
      ${up('tablet')} {
        max-height: 2.7rem;
        height: 2.7rem;
      }

      ${up('desktop')} {
        max-height: 3.5rem;
        height: 3.5rem;
      }

      ${up('largeDesktop')} {
        max-height: 5.6rem;
        height: 5.6rem;
      } 
    }


    & ~ main #nav-section {
      transform: translateY(-1rem);
      opacity: 0;
    }

  }

  .menu-wrapper.desktop {
    transform: translateX(100%);
  }

  label.hamburger-icon {    
    ${up('tablet')} {
      width: 2.7rem;
      height: 1.6rem;
    }
    
    ${up('desktop')} {
      width: 3.5rem;
      height: 2.2rem;
    }

    ${up('largeDesktop')} {
      width: 5.6rem;
      height: 3.5rem;
    }
  }
`

export default StyledHeader
