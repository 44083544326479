import React from 'react'
import Link from 'src/components/Typography/Link'
import Wrapper from './style'

interface Props {
  isMenuOpen: boolean
  handleMenuToggle: any
}

const MobileMenu = (props: Props) => {
  const { isMenuOpen, handleMenuToggle } = props

  return (
    <Wrapper>
      <input
        type="checkbox"
        name="hamburger"
        id="hamburger"
        checked={isMenuOpen}
        onChange={handleMenuToggle}
      />
      <label htmlFor="hamburger" className="hamburger-icon interactive"/>
      <div className="mobile list-menu">
        <label htmlFor="hamburger" className="close-icon"/>

        <ul className="menu-nav">
          <li><Link><a href="/">ГЛАВНАЯ</a></Link></li>
          <li><Link><a href="/expositions">ВЫСТАВКИ</a></Link></li>
          <li><span><a href="https://shop.psgallery.ru">МАГАЗИН</a></span></li>
          <li><Link><a href="/about">О ГАЛЕРЕЕ</a></Link></li>
        </ul>

        <ul className="menu-socials">
          <li>
            <Link>
              <a
                //eslint-disable-next-line
                target="_blank"
                rel="nofollow noopener"
                href="https://vk.com/psgallery"
              >VKONTAKTE</a>
            </Link>
          </li>
          <li>
            <Link>
              <a
                //eslint-disable-next-line
                target="_blank"
                rel="nofollow noopener"
                href="https://www.facebook.com/psgalleryru/"
              >FACEBOOK</a>
            </Link>
          </li>
          <li>
            <Link>
              <a
                //eslint-disable-next-line
                target="_blank"
                rel="nofollow noopener"
                href="https://www.instagram.com/psgallery.ru/"
                >INSTAGRAM</a>
            </Link>
          </li>
        </ul>

        <Link><a href="mailto:psgallery@yandex.ru" className="mailto">psgallery@yandex.ru</a></Link>
      </div>

    </Wrapper>
  )
}

export default MobileMenu
