import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  display: block;
  position: relative;
  float: right;
  z-index: 1500;

  label.hamburger-icon {
    display: flex;
    width: 2.6rem;
    height: 1.6rem;
    background-image: url(${require('src/static/icons/BurgerMenu.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform .7s ease-out;
    cursor: pointer;

    ${up('tablet')}{
      transform: scaleX(-1);
    }
  }

  input[type="checkbox"] {
    display: none;

    & ~ div.list-menu {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${props => props.theme.colors.backgroundDark};
      width: 100%;
      height: 100%;
      max-width: 100%;
      min-height: 100%;
      padding: 2.5rem 2rem 4rem 2rem;
      text-align: center;
      opacity: 0;
      z-index: -1;
      overflow-y: scroll;
      transform: scale(0);
      transition: transform 0s linear 1s,
                  opacity .5s ease .5s,
                  z-index .5s ease .5s;

      label.close-icon {
        opacity: 0;
        display: block;
        width: 3rem;
        height: 3rem;
        background-image: url(${require('src/static/icons/CloseMenu.svg')});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 auto 2.5rem auto;
        position: relative;
        transition: opacity .5s ease;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          bottom: -2.5rem;
          transform: translateX(-50%);
          width: 0;
          height: 1px;
          background-color: ${props => props.theme.colors.separator[700]};
          transition: width .5s ease;
        }
      }

      ul {
        display: block;
        list-style: none;
        text-align: center;
        justify-content: center;
        padding: 1.6rem 0;
        position: relative;
        opacity: 0;
        transition: opacity .5s ease;

        & > li {
          display: block;
          padding: 1.6rem 0;
          text-align: center;
          opacity: 0;
          transition: opacity .5s ease;

          & > span, & > span > a {
            display: inline-block;
            font-family: 'Montserrat';
            font-size: 1.2rem;
            letter-spacing: .15em;
            line-height: 1.25;
            font-weight: 700;
            text-transform: uppercase;
            color: ${props => props.theme.colors.bodyTextLight};
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 0;
          height: 1px;
          background-color: ${props => props.theme.colors.separator[700]};
          transition: width .5s ease;
        }
      }

      a.mailto {
        margin-top: 3.5rem;
        color: ${props => props.theme.colors.subtitle};
        opacity: 0;
        transition: opacity .5s ease;
      }
    }

    &:checked ~ div {
      width: 100%;
      height: 100%;
      max-width: 100%;
      min-height: 100%;
      z-index: 2;
      opacity: 1;
      transform: scale(1);
      transition: transform 0s linear,
                  opacity .5s ease,
                  z-index .5s ease;

      & label.close-icon {
        opacity: 1;
        transition: opacity .5s ease .5s;

        &::after {
          width: 17rem;
          transition: width .5s ease .75s;
        }
      }

      & ul.menu-nav {
        opacity: 1;
        transition: opacity .5s ease 1s;

        &::after {
          width: 17rem;
          transition: width .5s ease 1.75s;
        }

        & > li {
          opacity: 1;

          &:nth-child(1) {
            transition: opacity .5s ease 1s;
          }
          &:nth-child(2) {
            transition: opacity .5s ease 1.25s;
          }
          &:nth-child(3) {
            transition: opacity .5s ease 1.5s;
          }
          &:nth-child(4) {
            transition: opacity .5s ease 1.75s;
          }
        }

      }

      & ul.menu-socials {
        opacity: 1;
        transition: opacity .5s ease 2s;

        &::after {
          width: 17rem;
          transition: width .5s ease 2.5s;
        }

        & > li {
          opacity: 1;

          &:nth-child(1) {
            transition: opacity .5s ease 2s;
          }
          &:nth-child(2) {
            transition: opacity .5s ease 2.25s;
          }
          &:nth-child(3) {
            transition: opacity .5s ease 2.5s;
          }
        }
      }

      a.mailto {
        opacity: 1;
        transition: opacity .5s ease 2.75s;
      }
    }

  }
`

export default  Wrapper
