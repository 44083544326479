import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  badge?: boolean
  simple?: boolean
}

const StyledLink = styled.span<Props>`
  &, & > a {
    display: inline-block;
    font-family: ${props => props.theme.fontFamily.secondary};
    color: ${props => props.theme.colors.bodyTextDark};
    font-size: 1.2rem;
    letter-spacing: .15em;
    line-height: 1.25;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all .5s ease-in-out;
    /* cursor: pointer; */

    ${up('tablet')} {
      font-size: .9rem;
    }

    ${up('desktop')} {
      font-size: 1.2rem;
    }

    ${up('largeDesktop')} {
      font-size: 1.9rem;
    }

    ${props => props.simple ? `` : `
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -.8rem;
        width: 0%;
        height: 2px;
        background-color: ${props.theme.colors.separator[300]};
        transition: all .7s ease-out;
      }
    `}

    ${props => props.badge && `
      &::after {
        content: '';
        position: absolute;
        right: -10px;
        top: -4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${props.theme.colors.notifier};
      }
    `}

    &:hover::before {
        width: 100%;
    }
  }

`

export default StyledLink
