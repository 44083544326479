import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 100%;
  min-height: 100vh;
  min-height: calc(var(--vh) * 100);

  section {
    display: flex;
  }

  main {
    display: flex;
    flex-flow: column;
    flex: 0 0 auto;
    height: 100%;
    padding-top: 8.3rem;
    width: 100%;
    position: relative;
    overflow-x: hidden;

    &.centered {
      margin: auto;
    }

    ${up('tablet')} {
      flex: 1 0 auto;
      padding-top: 4.5rem;
      overflow-x: auto;
    }

    ${up('desktop')} {
      height: auto;
      padding-top: 7rem;
    }

    section.video-wrapper + section:not(.video-wrapper) {
      margin-top: 10rem;

      ${up('tablet')} {
        margin-top: 20rem;
      }

      ${up('desktop')} {
        margin-top: 24rem;
      }

      ${up('largeDesktop')} {
        margin-top: 50rem;
      }
    }

  }

`

export default Wrapper
