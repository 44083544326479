import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import Copyright from 'src/components/Typography/Copyright'
import Link from 'src/components/Typography/Link'
import theme from 'src/theme'
import StyledFooter from './style'

const Footer: React.FC = () => {
  const date:Date = new Date()

  return (
    <StyledFooter>
      <Grid lgColumnGap={theme.columnGutter.lg}>
        <Row>
          <Col xs={12} lg={4}>
            <Copyright>© 2010 — {date?.getFullYear()}</Copyright>
          </Col>
          <Col xs={12} lg={4}>
            <ul>
              <li>
                <Link>
                  <a
                    //eslint-disable-next-line
                    target="_blank"
                    rel="nofollow noopener"
                    href="https://vk.com/psgallery"
                  >VKONTAKTE</a>
                </Link>
              </li>
              <li>
                <Link>
                  <a
                    //eslint-disable-next-line
                    target="_blank"
                    rel="nofollow noopener"
                    href="https://www.facebook.com/psgalleryru/"
                    >FACEBOOK</a>
                </Link>
              </li>
              <li>
                <Link>
                  <a
                    //eslint-disable-next-line
                    target="_blank"
                    rel="nofollow noopener"
                    href="https://www.instagram.com/psgallery.ru/"
                    >INSTAGRAM</a>
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={4}>
            <Link simple><a href="mailto:psgallery@yandex.ru">psgallery@yandex.ru</a></Link>
          </Col>
        </Row>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
