import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Wrapper from './style'
// import anime from 'animejs'

const Layout: React.FC = props => {
  if ((typeof document !== 'undefined')) {
    const vh = document.documentElement.clientHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const listener = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  // const updatePage = () => {
  //   const cursor = document.querySelector('.circle-cursor')

  //   cursor?.classList.remove('hover')
  //   cursor?.classList.remove('scroll')

  //   anime({
  //     targets: '.circle-cursor',
  //     width: '48px',
  //     height: '48px',
  //     top: '-24px',
  //     left: '-24px',
  //     duration: 300,
  //     easing: 'linear'
  //   })
  // }

  // useEffect(() => {
  //   updatePage()
  // }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', listener)
      window.addEventListener('orientationchange', listener)
      return () => {
        window.removeEventListener('resize', listener)
        window.removeEventListener('orientationchange', listener)
      }
    }
  })

  // useEffect(() => {
  //   const cursor = document.querySelector('.circle-cursor')
  //   const zooms = document.querySelectorAll('.image-zoom')
  //   const links = document.querySelectorAll('a, .interactive, .slick-arrow')
  //   const scroll = document.querySelector('#horizontalScroll')
  //   const video = document.querySelectorAll('.video')

  //   let isScroll = false

  //   const handleMouseEnter = (state: string) => () => {
  //     if (state === 'scroll') {
  //       isScroll = true
  //       cursor?.classList.add('scroll')
  //     } else {
  //       cursor?.classList.remove('scroll')
  //     }

  //     if (state === 'hover') {
  //       anime({
  //         targets: '.circle-cursor',
  //         width: '80px',
  //         height: '80px',
  //         top: '-40px',
  //         left: '-40px',
  //         duration: 300,
  //         easing: 'linear'
  //       })
  //     }
  //     if (state === 'zoom') {
  //       cursor?.classList.add('zoom')
  //     }
  //     if (state === 'hide') {
  //       anime({
  //         targets: '.circle-cursor',
  //         width: '0',
  //         height: '0',
  //         top: '0',
  //         left: '0',
  //         opacity: '0',
  //         duration: 200,
  //         easing: 'linear'
  //       })
  //     }
  //   }

  //   const handleMouseLeave = (state: string) => () => {
  //     if (state === 'scroll') {
  //       isScroll = false
  //       cursor?.classList.remove('scroll')
  //     } else if (isScroll) {
  //       cursor?.classList.add('scroll')
  //     }

  //     if (state === 'hover') {
  //       anime({
  //         targets: '.circle-cursor',
  //         width: '48px',
  //         height: '48px',
  //         top: '-24px',
  //         left: '-24px',
  //         duration: 300,
  //         easing: 'linear'
  //       })
  //     }
  //     if (state === 'zoom') {
  //       cursor?.classList.remove('zoom')
  //     }

  //     if (state === 'hide') {
  //       anime({
  //         targets: '.circle-cursor',
  //         width: '48px',
  //         height: '48px',
  //         top: '-24px',
  //         left: '-24px',
  //         opacity: '1',
  //         duration: 200,
  //         easing: 'linear'
  //       })
  //     }
  //   }

  //   links.forEach(item => {
  //     item.addEventListener('mouseenter', handleMouseEnter('hover'))
  //     item.addEventListener('mouseleave', handleMouseLeave('hover'))
  //   })
  //   zooms.forEach(item => {
  //     item.addEventListener('mouseenter', handleMouseEnter('zoom'))
  //     item.addEventListener('mouseleave', handleMouseLeave('zoom'))
  //   })
  //   scroll?.addEventListener('mouseenter', handleMouseEnter('scroll'))
  //   scroll?.addEventListener('mouseleave', handleMouseLeave('scroll'))

  //   video.forEach(item => {
  //     item.addEventListener('mouseenter', handleMouseEnter('hide'))
  //     item.addEventListener('mouseleave', handleMouseLeave('hide'))
  //   })

  //   return () => {
  //     links.forEach(item => {
  //       item.removeEventListener('mouseenter', handleMouseEnter('hover'))
  //       item.removeEventListener('mouseleave', handleMouseLeave('hover'))
  //     })
  //     zooms.forEach(item => {
  //       item.removeEventListener('mouseenter', handleMouseEnter('zoom'))
  //       item.removeEventListener('mouseleave', handleMouseLeave('zoom'))
  //     })
  //     scroll?.removeEventListener('mouseenter', handleMouseEnter('scroll'))
  //     scroll?.removeEventListener('mouseleave', handleMouseLeave('scroll'))
  //     video.forEach(item => {
  //       item.removeEventListener('mouseenter', handleMouseEnter('hide'))
  //       item.removeEventListener('mouseleave', handleMouseLeave('hide'))
  //     })
  //   }
  // })

  useEffect(() => {
    const helmetItems = document.documentElement.querySelectorAll('[data-react-helmet]')
    helmetItems.forEach(item => item.removeAttribute('data-react-helmet'))
  }, [])

  return (
    <Wrapper>
      <Header />
        {props.children}
      <Footer />
    </Wrapper>
  )
}

export default Layout
