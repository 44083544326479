import React from 'react'
import { Link } from 'gatsby'

interface NavLinkProps {
  to: string
  exact?: boolean
}

const NavLink: React.FC<NavLinkProps> = props => {
  return (
    <Link
      to={props.to}
      activeClassName="active"
      partiallyActive={!props.exact}
    >
      {props.children}
    </Link>
  )
}

NavLink.defaultProps = {
  exact: false
}

export default NavLink
