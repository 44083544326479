import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledCopyright = styled.p`
  font-family: ${props => props.theme.fontFamily.secondary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.83;
  font-weight: 500;

  ${up('tablet')} {
    font-size: .9rem;
  }

  ${up('desktop')} {
    font-size: 1.2rem;
  }

  ${up('largeDesktop')} {
    font-size: 1.9rem;
  }

`

export default StyledCopyright
