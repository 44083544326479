import React, { useState, useEffect } from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import useMedia from 'src/utils/useMedia'
import Link from 'src/components/Typography/Link'
import theme from 'src/theme'
//@ts-ignore
import Logo from 'src/static/icons/Logo.inline.svg'
import MobileMenu from 'src/layout/Header/MobileMenu'
import DesktopMenu from 'src/layout/Header/DesktopMenu'
import StyledHeader from './style'

const getDocument = () => {
  if (typeof document !== 'undefined') {
    return document
  }

  return undefined
}

const Header: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isMenuFixed, setMenuFixed] = useState(false)
  const isMobile = useMedia('mobile')

  useEffect(() => {
    window?.addEventListener('scroll', listener)
    return () => {
      window?.removeEventListener('scroll', listener)
    }
  })

  const listener = () => {
    const scrollY = window?.scrollY ?? 0

    if (scrollY <= 50) {
      setMenuFixed(false)
    } else {
      (isMenuFixed !== true) && setMenuFixed(true)
    }
  }

  const handleMenuToggle = () => {

    const body = (getDocument()?.getElementsByTagName('body')?.[0] ?? <div />) as any
    const propgressBar = (getDocument()?.getElementsByClassName('bar')?.[0] ?? <div />) as any

    if (isMenuOpen) {
      if (isMobile) {
        body.style.overflow = ''
      }
      propgressBar.style.zIndex = '1031'
    } else {
      if (isMobile) {
        body.style.overflow = 'hidden'
      }
      propgressBar.style.zIndex = '-1'
    }
    setMenuOpen(!isMenuOpen)
  }

  return (
    <StyledHeader className={isMenuFixed ? 'fixed' : ''}>
      <Grid lgColumnGap={theme.columnGutter.lg}>
        <Row>
          <Col xs={6} lg={6}>
            <a className="logo interactive" href="/">
              <Logo />
            </a>
          </Col>
          <Col xs={6} lg={6} className="desktop about">
            <Link><a href="/about">О ГАЛЕРЕЕ</a></Link>
          </Col>
          <Col xs={6} lg={6} className="menu">
            <MobileMenu isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle} />
          </Col>
        </Row>
      </Grid>
      <DesktopMenu isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle}/>
    </StyledHeader>
  )
}

export default Header
