import React from 'react'
import StyledCopyright from './style'

interface Props {
  className?: string
  children: React.ReactNode
}

const Copyright = (props: Props) => {
  return (
    <StyledCopyright
      className={props.className}
    >
      {props.children}
    </StyledCopyright>
  )
}

export default Copyright
