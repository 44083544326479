import styled from 'styled-components'
import { down, up } from 'src/utils/media'

const StyledFooter = styled.footer`
  display: flex;
  flex-flow: row;
  width: ${props => props.theme.containerWidth.lg};
  margin: 0 auto;

  ${down('desktop')} {
    display: none;
  }

  ${up('tablet')} {
    width: ${props => props.theme.containerWidth.lg};
  }

  ${up('desktop')} {
    width: ${props => props.theme.containerWidth.xl};
  }

  & > div {
    margin-left: ${props => -props.theme.columnGutter.lg/2}px;
    margin-right: ${props => -props.theme.columnGutter.lg/2}px;

    .grid-row {
      padding: 0;

      ${up('tablet')} {
       margin-bottom: 4.5rem;
      }

      ${up('desktop')} {
       margin-bottom: 7rem;
      }

      & > div:nth-child(1) {
        display: flex;
        flex-flow: column;
        justify-content: center;
      }

      & > div:nth-child(2) {
        align-items: center;

        ul {
          display: flex;
          flex-flow: row;
          justify-content: center;

          li {
            list-style: none;
            font-size: inherit;
            margin: 0 4em;
          }
        }
      }

      & > div:nth-last-child(1) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;

        & > span {
          float: right;
        }
      }

    }
  }
`

export default StyledFooter
