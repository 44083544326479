import React from 'react'
import StyledLink from './style'

interface Props {
  className?: string
  badge?: boolean
  simple?: boolean
  children: React.ReactNode
}

const Link = (props: Props) => {
  return (
    <StyledLink
      className={props.className}
      badge={props.badge}
      simple={props.simple}
    >
      {props.children}
    </StyledLink>
  )
}

export default Link
