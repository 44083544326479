import NotificationRepository from 'src/domain/repositories/NotificationsRepository'

class LocalNotificationRepository implements NotificationRepository {
  getNewsNotification(id: string): Promise<boolean> {
    const notification = localStorage.getItem('NEWS_NOTIFICATION')
    const hasNotification = `${notification}` !== `${id}`
    // console.log(hasNotification, notification, id)
    return Promise.resolve(hasNotification)
  }

  removeNewsNotification(id: string): Promise<void> {
    // console.log('set', id)
    localStorage.setItem('NEWS_NOTIFICATION', id)
    return Promise.resolve()
  }
}

export default LocalNotificationRepository
